<template>
  <Modal />
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <ConfigNavBar />
      <div class="bottom-margin"></div>
      <div class="grid-table-cell-wide column">
        <input type="text" placeholder="Name" v-model="newCallee.callee_name" />
      </div>
      <div class="grid-table-cell-wide row">
        <div style="display: flex; justify-content: space-between; width: 100%">
          +1 &nbsp;
          <input type="tel" placeholder="212" v-model="newCallee.area_code" maxlength="3"
            style="width: 20%; margin-right: 2%; text-align: center" title="Only numeric values are allowed." />
          <input type="tel" placeholder="555" v-model="newCallee.prefix" maxlength="3"
            style="width: 20%; margin-right: 2%; text-align: center" title="Only numeric values are allowed." />
          <input type="tel" placeholder="1234" v-model="newCallee.suffix" maxlength="4"
            style="width: 38%; text-align: center" title="Only numeric values are allowed." />
        </div>
      </div>
      <!-- <div class="grid-table-cell-wide column">
        default background text
        <textarea placeholder="Briefly describe their background, a shared project, and any special instructions."
          rows="5" v-model="newCallee.background_text"></textarea>
      </div> -->
      <div class="grid-table-cell-wide column">
        {{ settings.formatUIString(ui_string.callee_instruct_title, { callee_name: newCallee.callee_name }) }}
        <textarea placeholder="Briefly describe their background, a shared project, and any special instructions."
          rows="5" v-model="newCallee.callee_instruct"></textarea>
      </div>
      <div class="grid-table-cell-narrow column">
        <button @click="calleeCreate">Save</button>
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import { storeToRefs } from 'pinia';
import ConfigNavBar from "./ConfigNavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";
import { useSettingsStore } from "../store";

const router = useRouter();
const settings = useSettingsStore();
const { showModal, family_id, BACK_URL, caller_id, callee_id, callee_name, callee_phone_number, ui_string } = storeToRefs(settings);
const newCallee = ref({
  callee_name: '',
  area_code: '',
  prefix: '',
  suffix: '',
  background_text: '',
  family_id: '' 
});

// Fetch settings on component mount
onMounted(async () => {
  await settings.fetchSettings();
  newCallee.value.family_id = family_id.value; 
});

const calleeCreate = async () => {
  showModal.value = true;
  const phone_number = `+1${newCallee.value.area_code}${newCallee.value.prefix}${newCallee.value.suffix}`;
  const payload = {
    family_id: newCallee.value.family_id,  // Use updated family_id
    phone_number: phone_number,
    callee_name: newCallee.value.callee_name,
    background_text: newCallee.value.background_text,
    callee_instruct: newCallee.value.callee_instruct,
    caller_id: caller_id.value,
    callee_id: callee_id.value,

  };
  try {
    const response = await axios.post(`${BACK_URL.value}/callee/callee_create`, payload);
    const newCalleeData = response.data;

    // Update settings with the new callee data
    callee_id.value = newCalleeData.id;
    callee_name.value = newCalleeData.callee_name;
    callee_phone_number.value = newCalleeData.phone_number;

    await settings.saveSettings();
    await settings.fetchSettings();
    await settings.fetchCallee();
    router.push('/'); 
  } catch (error) {
    console.error("Failed to create callee:", error.response?.data?.detail || error.message);
    alert(`Failed to create callee: ${error.response?.data?.detail || error.message}`);
  } finally {
    showModal.value = false;
  }
};
</script>

<style scoped>
</style>

