<template>
  <Modal />
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <NavBar />
      <template v-if="calls_list_result.length > 0">
        <div style="margin-bottom: 15px;"></div>
        <div class="grid-table-cell-wide column">
          <div class="label-text">
            {{ settings.formatUIString(ui_string.answer_list_title_everyone, {
        callee_name: callee_all ? 'Everyone' : callee_name,
        caller_name: caller_all ? 'Everyone' : caller_name
      }) }}
          </div>
          <input type="text" id="question_subject" name="question_subject" class="input-field"
            placeholder="Generated question subject" v-model="editedQuestion.question_subject"
            @input="onQuestionEdit" />
        </div>
        <div class="grid-table-cell-wide column">
          <div class="label-text">{{ ui_string.reply_question_title }}</div>
          <textarea id="question_display" name="question_display" ref="questionDisplayRef" rows="10" cols="75"
            v-model="editedQuestion.question_text" @input="onQuestionEdit"></textarea>
        </div>
        <div v-if="isQuestionEdited" class="grid-table-cell-narrow">
          <button @click="saveQuestionChanges">Save</button>
        </div>
        <div class="grid-table-cell-wide column">
          <audio controls v-if="calls_list_result[currentCallIndex].question.question_audio_id"
            :src="`${FRONT_URL}/question_audio/${calls_list_result[currentCallIndex].question.question_audio_id}.mp3`"></audio>
        </div>


        <template v-for="(answer, index) in calls_list_result[currentCallIndex].answers" :key="index">
          <div class="grid-table-cell-wide column">
            <div class="label-text">
              <label for="answer_text">{{ ui_string.reply_answer_title }}</label>
            </div>
            <textarea id="answer_text" name="answer_text" ref="answerDisplayRef" rows="20" cols="75" v-model="editedAnswers[index].answer_text" @input="onAnswerEdit(index)"
            ></textarea>
          </div>
          <div v-if="editedAnswers[index].isEdited" class="grid-table-cell-narrow">
            <button @click="saveAnswerChanges(index)">Save</button>
          </div>
          <div class="grid-table-cell-wide column">
            <audio controls :src="`${FRONT_URL}/answer_audio/${answer.answer_audio_id}.mp3`"></audio>
          </div>
        </template>
        <div class="drop-shadow"> </div>

        <div class="grid-table-cell-wide column">
          <div class="label-text">{{ ui_string.reply_new_question_title }}</div>
          <textarea v-model="questionNudge" :placeholder="ui_string.reply_nudge_default" class="nudge-input"
            rows="5"></textarea>
        </div>

        <div class="grid-table-cell-narrow top-margin">
          <button @click="createFollowUp">{{ ui_string.reply_followup_button }}</button>
        </div>
        <div class="grid-table-cell-narrow top-margin">
          <button @click="askAgain">{{ ui_string.reply_again_button }}</button>
        </div>

        <div class="grid-table-cell-narrow top-margin ignore-section">
          <label class="ignore-checkbox-container">
            <input type="checkbox" id="ignoreCheckbox" v-model="ignoreForNewQuestions"
              @change="toggleAnswerSelection(calls_list_result[currentCallIndex].call_sid)" class="ignore-checkbox">
            <span>{{ ui_string.reply_ignore_toggle }}</span>
          </label>
        </div>


        <div class="grid-table-cell-narrow top-margin">
          <button @click="callDelete(calls_list_result[currentCallIndex].call_sid)">{{ ui_string.reply_delete_button
            }}</button>
        </div>

      </template>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watchEffect, computed } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from 'pinia';
import { useSettingsStore } from "../store";
import axios from "axios";
import NavBar from "./NavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";

const router = useRouter();
const settings = useSettingsStore();
const { showModal, caller_phone_number, caller_id, BACK_URL, FRONT_URL, timezone, callee_id, callee_name, caller_name, callee_phone_number, family_instruct, caller_instruct, callee_instruct, callee_all, caller_all, ui_string } = storeToRefs(settings);
const calls_list_result = ref([]);
const currentCallIndex = ref(0);
const qaList = ref([]);
const questionNudge = ref('');

const editedQuestion = ref({});
const isQuestionEdited = ref(false);

const editedAnswers = ref([]);

const call_history_get = async () => {
  showModal.value = true;
  // Check if a specific call_sid is provided in the route parameters
  const call_sid = router.currentRoute.value.params.call_sid;
  const url = `${BACK_URL.value}/call/call_get_bysid/${call_sid}`;

  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      // If fetching a specific call, wrap the result in an array to maintain the data structure
      calls_list_result.value = call_sid ? [response.data] : response.data;
      // If a specific call is fetched, set the currentCallIndex to 0 as there will only be one record
      if (call_sid) {
        currentCallIndex.value = 0;
      }
    } else {
      console.error("HTTP-Error: " + response.status);
    }
  } catch (error) {
    console.error("Failed to fetch call history:", error);
  } finally {
    showModal.value = false;
  }
};

const ignoreForNewQuestions = computed(() => {
  return calls_list_result.value[currentCallIndex.value].answers.some(answer => answer.answer_qa_is_selected === 'False');
});

const toggleAnswerSelection = async (callSid) => {
  const newSelectionState = !ignoreForNewQuestions.value;

  try {
    const response = await axios.put(`${BACK_URL.value}/answer/answer_is_selected`, {
      call_sid: callSid,
      answer_qa_is_selected: newSelectionState ? 'False' : 'True'
    });

    if (response.status === 200) {
      // Update the local state to reflect the change
      calls_list_result.value[currentCallIndex.value].answers.forEach(answer => {
        answer.answer_qa_is_selected = newSelectionState ? 'False' : 'True';
      });
    } else {
      console.error("HTTP-Error: " + response.status);
    }
  } catch (error) {
    console.error("Failed to update answer selection:", error);
  }
};

const callDelete = async (callSid) => {
  if (window.confirm(ui_string.reply_delete_dialog)) {
    try {
      const response = await fetch(`${BACK_URL.value}/call/call_delete_bysid/${callSid}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      router.push("/call_history_list");
    } catch (error) {
      console.error("Failed to delete call:", error);
    }
  }
};

const createFollowUp = async () => {
  try {
    showModal.value = true;

    const qa_of_focus = {
      question_subject: calls_list_result.value[currentCallIndex.value].question.question_subject,
      question_text: calls_list_result.value[currentCallIndex.value].question.question_text,
      answers: calls_list_result.value[currentCallIndex.value].answers.map(answer => ({
        answer_text: answer.answer_text
      }))
    };
    const followUpData = {
      callee_id: callee_id.value,
      caller_id: caller_id.value,
      family_instruct: family_instruct.value,
      callee_qa_list: JSON.stringify(qaList.value),
      callee_instruct: callee_instruct.value,
      callee_name: callee_name.value,
      caller_name: caller_name.value,
      caller_instruct: caller_instruct.value,
      qa_of_focus: qa_of_focus,
      timezone: timezone.value,
      question_nudge: questionNudge.value
    };

    const response = await axios.post(`${BACK_URL.value}/ai/question_generate_followup`, followUpData);


    console.log('Generated Messages Response:');
    console.log('===== System Prompt =====');
    console.log(response.data.settings_system_prompt);
    console.log('\n===== User Prompt =====');
    console.log(response.data.settings_user_prompt);
    console.log('\n===== Followup Questions =====');
    console.log(JSON.stringify(response.data.followup_questions, null, 2));

    if (response.status === 200) {
      console.log("Follow-up questions created successfully");
      router.push("/question_list");
    }
  } catch (error) {
    console.error("Failed to create follow-up questions:", error);
  } finally {
    showModal.value = false;
  }
};

const askAgain = async () => {
  try {
    showModal.value = true;

    const qa_of_focus = {
      question_subject: calls_list_result.value[currentCallIndex.value].question.question_subject,
      question_text: calls_list_result.value[currentCallIndex.value].question.question_text,
      answers: calls_list_result.value[currentCallIndex.value].answers.map(answer => ({
        answer_text: answer.answer_text
      }))
    };

    const requestData = {
      callee_id: callee_id.value,
      caller_id: caller_id.value,
      family_instruct: family_instruct.value,
      callee_qa_list: JSON.stringify(qaList.value),
      callee_instruct: callee_instruct.value,
      callee_name: callee_name.value,
      caller_name: caller_name.value,
      caller_instruct: caller_instruct.value,
      qa_of_focus: qa_of_focus,
      timezone: timezone.value,
      question_nudge: questionNudge.value
    };

    const response = await axios.post(`${BACK_URL.value}/ai/ask_again`, requestData);
    console.log('Generated Messages Response:');
    console.log('===== System Prompt =====');
    console.log(response.data.settings_system_prompt);
    console.log('\n===== User Prompt =====');
    console.log(response.data.settings_user_prompt);
    console.log('\n===== Repeat Questions =====');
    console.log(JSON.stringify(response.data.repeat_questions, null, 2));

    if (response.status === 200) {
      console.log("Follow-up questions created successfully");
      console.log('Generated Messages Response:', response.data);
      router.push("/question_list");
    } else {
      console.error("HTTP-Error: " + response.status);
    }
  } catch (error) {
    console.error("Failed to create follow-up questions:", error);
  } finally {
    showModal.value = false;
  }
};


const fetchQAList = async () => {
  try {
    const payload = {
      callee_id: callee_id.value,
      caller_id: caller_id.value
    };
    const response = await axios.post(`${BACK_URL.value}/answer/qalist_get_bycalleeid`, payload, {
      withCredentials: true
    });
    qaList.value = response.data;
  } catch (error) {
    console.error('Failed to fetch QA list:', error);
  }
};

const onQuestionEdit = () => {
  isQuestionEdited.value = true;
};

const saveQuestionChanges = async () => {
  if (window.confirm("Are you sure you want to save the changes to the question?")) {
    try {
      showModal.value = true;
      const params = new URLSearchParams({
        question_id: calls_list_result.value[currentCallIndex.value].question.id,
        question_subject: editedQuestion.value.question_subject,
        question_text: editedQuestion.value.question_text,
      });
      const response = await axios.post(`${BACK_URL.value}/question/question_update?${params.toString()}`);
      if (response.status === 200) {
        calls_list_result.value[currentCallIndex.value].question.question_subject = editedQuestion.value.question_subject;
        calls_list_result.value[currentCallIndex.value].question.question_text = editedQuestion.value.question_text;
        isQuestionEdited.value = false;
      }
    } catch (error) {
      console.error("Failed to update question:", error);
    } finally {
      showModal.value = false;
    }
  }
};

watchEffect(() => {
  if (calls_list_result.value.length > 0) {
    editedQuestion.value = {
      question_subject: calls_list_result.value[currentCallIndex.value].question.question_subject,
      question_text: calls_list_result.value[currentCallIndex.value].question.question_text,
    };
    editedAnswers.value = calls_list_result.value[currentCallIndex.value].answers.map(answer => ({
      id: answer.id,
      answer_text: answer.answer_text,
      isEdited: false
    }));
  }
});

const onAnswerEdit = (index) => {
  editedAnswers.value[index].isEdited = true;
};

const saveAnswerChanges = async (index) => {
  if (window.confirm("Are you sure you want to save the changes to the answer?")) {
    try {
      showModal.value = true;
      const response = await axios.put(`${BACK_URL.value}/answer/answer_update`, {
        answer_id: editedAnswers.value[index].id,
        answer_text: editedAnswers.value[index].answer_text,
      });
      if (response.status === 200) {
        calls_list_result.value[currentCallIndex.value].answers[index].answer_text = editedAnswers.value[index].answer_text;
        editedAnswers.value[index].isEdited = false;
      }
    } catch (error) {
      console.error("Failed to update answer:", error);
    } finally {
      showModal.value = false;
    }
  }
};

onMounted(async () => {
  try {
    await call_history_get();
    await fetchQAList();
    // if (router.currentRoute.value.params.call_sid) {
    //   const call_sid = router.currentRoute.value.params.call_sid;
    //   let index = calls_list_result.value.findIndex((call) => call.call_sid === call_sid);
    //   currentCallIndex.value = index;
    // }
  } catch (error) {
    console.error(`Failed to fetch data: ${error}`);
  } finally {
    showModal.value = false;  // Ensure modal is closed after all operations
  }
});

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>
<style scoped>
.ignore-section {
  display: flex;
  justify-content: center;
}

.ignore-checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ignore-checkbox {
  margin-right: 12px;
  appearance: none;
  -webkit-appearance: none;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.ignore-checkbox:checked::after {
  content: '\2714';
  font-size: 16px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ignore-checkbox-label {
  user-select: none;
  font-size: 16px;
}
</style>
